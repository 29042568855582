<template>
  <v-container fluid>
    <Tabla
      :items="{ headers, garantias, titulo: 'Experiencia de usuario' }"
      @actualizar="actualizar"
    ></Tabla>
  </v-container>
</template>

<script>
import PostService from "../../PostService";
import Tabla from "../../components/admin/Tabla";
export default {
  name: "admin-experiencia-usuario",
  components: {
    Tabla
  },
  data: () => ({
    headers: [
      {
        text: "ID",
        align: "start",
        value: "id_experiencia"
      },
      { text: "Comericial", value: "distribuidor" },
      { text: "Fecha de servicio", value: "fecha_servicio" },
      { text: "Nombre", value: "nombre" },
      { text: "Apellido", value: "telefono" },
      { text: "Email", value: "email" },
      { text: "Lugar Adquisición", value: "num_serie" },
      // { text: "Modelo", value: "modelo" },
      // { text: "Resueltos", value: "resueltos_prob" },
      // { text: "Limpieza", value: "limpia" },
      // { text: "Califiación de precio", value: "precio_calificacion" },
      // { text: "Actitud personal", value: "actitud_personal" },
      // { text: "Limpieza de taller", value: "limpieza_taller" },
      // { text: "Disponibilidad geniuna", value: "disponibilidad_genuina" },
      // { text: "Califiación del taller", value: "calificacion_taller" },
      // { text: "Recomendaría", value: "recomendaria" }
      { text: "Acciones", value: "action", sortable: false }
    ],
    garantias: []
  }),
  created() {
    this.actualizar();
  },
  methods: {
    async actualizar() {
      this.garantias = [];
      const logged = this.$store.getters.token;
      if (logged === "") return this.$router.push({ name: "admin" });
      const datosGarantias = await PostService.getExperienciaUsuario(logged);
      this.garantias = datosGarantias.data;
    }
  }
};
</script>

<style></style>
